import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { theme } from '../styles/theme';
import { ThemeProvider } from '../styles/ThemeProvider';

const WholePageOpacity = loadable(() => import('../animations/whole-page-opacity'));
const Head = loadable(() => import('../components/Head'));
const Layout = loadable(() => import('../components/Layout'));
const SingleReportMain = loadable(() =>
  import('../components/praise-reports/single-report/single-report-main'),
);

export const query = graphql`
  query PraiseCardReportsTempletQuery($slug: String) {
    contentfulACardPraiseReports(slug: { eq: $slug }) {
      excerpt
      slug
      title
      workEmployment
      surpriseBlessings
      relationshipWithGod
      psychologicalHealing
      physicalHealing
      fruitOfTheWomb
      financialBreakthrough
      familyRestoration
      education
      divineProvision
      divineProtectionDeliverance
      divineHelp
      businesses
      content {
        raw
      }
     
    }
  }
`;
const PraiseReports = props => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Head title="Praise Reports" />
        <WholePageOpacity>
          {/*<div>{props?.pageResources?.json.data.contentfulACardPraiseReports.title}</div>*/}
          <SingleReportMain props={props} />
        </WholePageOpacity>
      </Layout>
    </ThemeProvider>
  );
};
export default PraiseReports;
